import React, {Component} from 'react';
import CountUp from 'react-countup/build';
import VisibilitySensor from 'react-visibility-sensor';

import $ from 'jquery';

class CountersOffer extends Component {
    constructor(props) {
        super(props);
        this.startFunctions = [];
    }

    getCategoryName(category) {
        switch (category) {
            case 71:
                return 'dla oświaty';
            case 13:
                return 'dla księgowości i kadr';
            default:
                return '';
        }
    }

    render() {
        const {category} = this.props

        return (
            <div className="home-counters">
                <h2>Educado w liczbach</h2>
                <VisibilitySensor
                    onChange={isVisible => {
                        if (isVisible) {
                            this.startFunctions.forEach(start => {
                                start();
                            });
                            $('.js-counter-container').addClass('js-animated-border');
                            this.startFunctions = [];
                        }
                    }}
                    delayedCall
                    partialVisibility
                >
                    <div className="counters-container">
                        <div className="counter-container js-counter-container">
                            <div>
                                <CountUp start={0} end={category === 71 ? 300: 400} duration={3}>
                                    {({countUpRef, start}) => {
                                        this.startFunctions = [
                                            ...this.startFunctions,
                                            start
                                        ];
                                        return (
                                            <div className="counter-wrapper">
                                                <span>+</span><span className="counter" ref={countUpRef}/>
                                            </div>
                                        );
                                    }}
                                </CountUp>
                                <span className="text">szkoleń <br/>{category ? this.getCategoryName(category) : ''}</span>
                            </div>
                        </div>
                        <div className="counter-container js-counter-container">
                            <div>
                                <CountUp start={0} end={40} duration={3}>
                                    {({countUpRef, start}) => {
                                        this.startFunctions = [
                                            ...this.startFunctions,
                                            start
                                        ];
                                        return (
                                            <div className="counter-wrapper">
                                                <span>+</span><span className="counter" ref={countUpRef}/>
                                            </div>
                                        );
                                    }}
                                </CountUp>
                                <span className="text">ekspertów</span>
                            </div>
                        </div>
                        <div className="counter-container js-counter-container">
                            <div>
                                <CountUp start={0} end={300} duration={3}>
                                    {({countUpRef, start}) => {
                                        this.startFunctions = [
                                            ...this.startFunctions,
                                            start
                                        ];
                                        return (
                                            <div className="counter-wrapper">
                                                <span>+</span><span className="counter" ref={countUpRef}/><span>h</span>
                                            </div>
                                        );
                                    }}
                                </CountUp>
                                <span className="text">materiałów</span>
                            </div>
                        </div>
                        <div className="counter-container js-counter-container">
                            <div>
                                <CountUp start={0} end={700} duration={3}>
                                    {({countUpRef, start}) => {
                                        this.startFunctions = [
                                            ...this.startFunctions,
                                            start
                                        ];
                                        return (
                                            <div className="counter-wrapper">
                                                <span>+</span><span className="counter" ref={countUpRef}/>
                                            </div>
                                        );
                                    }}
                                </CountUp>
                                <span className="text">uczestników<small>szkoleń</small></span>
                            </div>
                        </div>
                    </div>
                </VisibilitySensor>
            </div>
        );
    }
}

export default CountersOffer;
