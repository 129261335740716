import React from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";

import dateFormat from "dateformat";
import striptags from "striptags";
import {strNormalize} from "../../common/utils";

class ExpertProductList extends React.Component {

    render() {
        const {experts, isAuthenticated} = this.props;
        function getBadge(subType) {
            switch (subType) {
                case 'webinar':
                    if (isAuthenticated && isAuthenticated) {
                        return ('webinar')
                    } else {
                        return ('webinar bezpłatny')
                    }
                case 'webinarPaid':
                    if (isAuthenticated && isAuthenticated) {
                        return ('webinar płatny')
                    } else {
                        return ('webinar Live')
                    }
                default:
                    return ('Szkolenie');
            }
        }

        function getButtons(subType, product, isAuthenticated) {
            switch (subType) {
                case 'webinar':
                    return (<>{isAuthenticated && isAuthenticated ?
                        <div className={'button-wrapper'}>
                            <Button href={`/${strNormalize(product.subType)}/${strNormalize(product.name)}/${(product.id)}`}
                                    className="educado-btn educado-btn-black">Oglądaj bezpłatnie</Button>
                            <a href={`/${strNormalize(product.subType)}/${strNormalize(product.name)}/${(product.id)}`}
                               className="training-btn">Więcej o webinarze</a>
                        </div>
                        :
                        <div className={'button-wrapper'}>

                            <Button href={product.categories[0].id === 71 ? '/oswiata/abonament' : '/ksiegowosc-i-kadry/abonament'} className="educado-btn educado-btn-black">Zapisz się</Button>
                            <a href={`/${strNormalize(product.subType)}/${strNormalize(product.name)}/${(product.id)}`}
                               className="training-btn">Więcej o webinarze</a>
                        </div>
                    }</>);
                case 'webinarPaid':
                    return (<>{isAuthenticated && isAuthenticated ?
                        <div className={'button-wrapper'}>
                            <Button href={`/${strNormalize(product.subType)}/${strNormalize(product.name)}/${(product.id)}`}
                                    className="educado-btn educado-btn-black">Oglądaj</Button>
                        </div>
                        :
                        <div className={'button-wrapper'}>
                            <Button href={`/rejestracja-webinar/${product.id}`}
                                    className={'educado-btn educado-btn-text black'}>kup
                                dostęp jednorazowy</Button>
                            <Button  href={product.categories[0].id === 71 ? '/oswiata/abonament' : '/ksiegowosc-i-kadry/abonament'} className="educado-btn educado-btn-black">Kup w abonamencie</Button>
                            <a href={`/${strNormalize(product.subType)}/${strNormalize(product.name)}/${(product.id)}`}
                               className="training-btn">Więcej o webinarze</a>
                        </div>
                    }</>);
                default:
                    return (<>{isAuthenticated && isAuthenticated ?
                        <div className={'button-wrapper'}>
                            <Button
                                href={`/${strNormalize(product.subType)}/${strNormalize(product.name)}/${(product.id)}`}
                                className="educado-btn educado-btn-black">Oglądaj</Button>
                        </div>
                        :
                        <div className={'button-wrapper'}>
                            <Button
                                href={product.categories[0].id === 71 ? '/oswiata/abonament' : '/ksiegowosc-i-kadry/abonament'}
                                className="educado-btn educado-btn-black">Kup w abonamencie</Button>
                            <a href={`/${strNormalize(product.subType)}/${strNormalize(product.name)}/${(product.id)}`}
                               className="training-btn">Więcej o szkoleniu</a>
                        </div>
                    }</>);
            }
        }

        return (
            <Container>
                <h3 className="headline-small text-center">Szkolenia eksperta</h3>
                <Container>
                    <Row className="expert-products">
                        {experts.activeTrainings && experts.activeTrainings.map((product, index) => (
                            <Col key={index} lg={6} xl={4} md={6} className={'mt-4 list-item-container'}>
                                <div className={"index-list-item"}>
                                    <div className={"card-img-top"} width={"120"} height={"147"}
                                         style={{backgroundImage: `url(${product.imageUrl})`}}/>
                                    <div
                                        className={`webinar-labels category-${product.categories[0].id ? product.categories[0].id : ''} ${product.archive ? 'with-overdue' : ''}`}>
                                        <div
                                            className={"archived-label"}>{getBadge(product.subType)}
                                        </div>
                                        {product.categories ?
                                            <div
                                                className={`category-label`}>{product.categories.map((category, index) => (

                                                <span
                                                    key={index}>{category.name} {product.categories.length > index + 1 && product.categories.length > 1 ? ' | ' : ''}</span>

                                            ))}</div>
                                            : ''
                                        }
                                        {product.archive ?
                                            <div className={"archived-label overdue"}>Archiwalne</div> : ''}
                                    </div>
                                    <Card.Body>
                                        <Card.Title
                                            title={product.name}>{product.name.length > 68 ? product.name.substring(0, 65) + '...' : product.name}</Card.Title>
                                        {product.experts ?

                                            <div className={"expert-wrapper"}>{product.experts.map((expert, index) => (
                                                <div className={"webinar-item-expert"}
                                                     key={index}>&nbsp;{expert.name} {expert.surname}{product.experts.length > index + 1 && product.experts.length > 1 ? ', ' : ''}</div>
                                            ))}</div>
                                            : <div className={"webinar-item-expert"}></div>}

                                        <div className={'item-info-container'}><p>Stan prawny
                                            na: <span>{dateFormat(product.legalStatusDate, 'dd.mm.yyyy')}</span></p>
                                        </div>
                                    </Card.Body>
                                    <Card.Text className="item-lead">
                                        {product.shortDescription ?
                                            <span>{striptags(product.shortDescription)}</span> : ''}

                                    </Card.Text>

                                    {getButtons(product.subType, product, isAuthenticated)}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Container>
        )
    }
}

export default ExpertProductList;