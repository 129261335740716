import React from "react";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import {iriFormatId} from "../../common/apiUtils";
import ProductQuiz from "./quiz/ProductQuiz";
import Auth from "../Auth";
import {parseTrainingMinutes, parseTrainingTime, strNormalize} from "../../common/utils";
import ProductDocuments from "./ProductDocuments";
import ProductSocialMedia from "./ProductSocialMedia";
import dateFormat from "dateformat";
import ProductFavorite from "./ProductFavorite";
import striptags from "striptags";
import ProductContent from "./ProductContent";

export const contentType = {
    forWhichUsers: 'Dla kogo',
    problems: 'O szkoleniu',
    program: 'Program',
    legalBasis: 'Podstawa prawna'
};

class ProductOnlineContent extends React.Component {

    getDescription(product) {
        return <>
            {this.getTrainingsContents(product)}
        </>
    }

    getTrainingsContents(product) {
        return product.trainingsContents.map((item, key) => (
            <div key={key} className={item.type === 'program' ? 'program' : ''}>
                <h3 className={"content-headline"}>{contentType[item.type]}</h3>
                {/*{item.content}*/}
                <p className={product.categories.some(category => category.name === "Oświata") ?
                    "oswiata" : 'kik'} dangerouslySetInnerHTML={{__html: striptags(item.content, ['ul', 'strong', 'p', 'ol', 'br', 'li'], `\n`)}}/>
            </div>
        ));
    }

    // static getPatronage(product) {
    //     return (
    //         <>
    //             <h3 className="webinar-headline-small">partnerzy</h3>
    //             <div className={"webinar-patronage"}>
    //                 {product.patronage.map((patronage, key) => (
    //                     <Col sm={12} md={6} lg={6} xl={4} key={key} className={"product-patronage-col"}>
    //                         {patronage.url ?
    //                             <a href={patronage.url} rel='noreferrer' target={"_blank"}>
    //                                 <div className={"product-patronage-content"}>
    //                                     <img
    //                                         src={patronage.imageUrl}
    //                                         alt={patronage.alt}
    //                                     />
    //                                 </div>
    //                             </a> : ''
    //                         }
    //                     </Col>
    //                 ))}
    //             </div>
    //         </>);
    // }

    getExpert(product) {
        return product.experts.map((expert, key) => (
            <>
                {key === 0 ? <h3 className={'mt-5'}>Ekspert</h3> : ''}
                <Row key={key} className={'mt-5 product-expert-container'}>
                    <Col sm={4} md={3}>
                        {expert.imageUrl ?
                            <img
                                className="product-expert-image"
                                src={expert.imageUrl}
                                alt={`${expert.name} ${expert.surname}`}
                            /> : <img
                                className="product-expert-image"
                                src={"/expert-default.png"}
                                alt={`${expert.name} ${expert.surname}`}
                            />
                        }
                    </Col>
                    <Col sm={8} md={9} className={'product-expert-description'}>
                        <h3><a
                            href={`/ekspert/${strNormalize(expert.name)}-${strNormalize(expert.surname)}/${iriFormatId(expert)}`}
                            className="d-inline-block">{expert.name} {expert.surname}</a></h3>
                        <h5>{expert.academicDegree}</h5>
                        <div dangerouslySetInnerHTML={{__html: expert.shortDescription}}/>
                        <a href={`/ekspert/${strNormalize(expert.name)}-${strNormalize(expert.surname)}/${iriFormatId(expert)}`}
                           className="d-inline-block">Więcej szkoleń autora</a>
                    </Col>
                </Row>
            </>
        ));
    }

    getQuiz() {
        const {auth, product} = this.props.children;

        if (auth.isAuthenticated && !product.accessDenied && product.quiz !== null) {
            if (product.quiz && product.quiz.id) {
                return (<div id={"quiz"}>
                        <ProductQuiz auth={auth} product={product}/></div>
                );
            }
            return (<h3 id={"quiz"} className={"mt-5"}>Test wiedzy nie jest dostępny dla tego szkolenia!</h3>);
        }
        if (auth.isAuthenticated && product.accessDenied && auth.decreaseLevel) {
            return (<h3 id={"quiz"} className={"mt-5"}>Twój abonament wygasł!</h3>);
        }
        return (<><h3 id={"quiz"} className={"mt-5"}>Zaloguj się aby rozpocząć test</h3>
            <div className={"quiz-login-btn"}><Auth params={{link: true}}/></div>
        </>);
    }

    getTeachingMaterials() {
        const {auth, product} = this.props.children;

        if (auth.isAuthenticated ) {
            if (product.accessDenied) {
                return (<>
                        <div className="product-content-sheets" id={"sheets"}>
                            <h3 className={"mt-5"}>Materiały dodatkowe</h3>
                            Nie masz dostępu do materiałów! Odnów abonament.
                        </div>
                    </>
                );

            } else {
                return (<>
                        <div className="product-content-sheets" id={"sheets"}>
                            <h3 className={"mt-5"}>Materiały dodatkowe</h3>
                            <ProductDocuments children={this.props.children}/>
                        </div>
                    </>
                );
            }
        } else {
            return (
                <div className="product-content-sheets" id={"sheets"}>
                    <h3 className={"mt-5"}>Materiały dodatkowe</h3>
                    <p> Nie masz dostepu do materiałów dodatkowych.</p>
                    <p>Kup dostęp lub zaloguj się.</p>
                    <div className={"product-content-sheets-button-container"}>
                    <div className={"quiz-login-btn"}><Auth params={{link: true}}/></div>
                    <Button href={product.categories[0].name === "Oświata" ?  "/oswiata/abonament" : '/ksiegowosc-i-kadry/abonament'} className={'educado-btn educado-btn-black'} title={"oferta"}>Kup w abonamencie</Button>
                    </div>
                </div>)
        }
    }

    render() {
        const {auth, product, isFetching} = this.props.children;
        if (isFetching)
            return (<Spinner animation="grow"/>);
        return (
            <>
                {product.patronage.length > 0 ? ProductContent.getPatronage(product) : ''}
                {this.getTrainingsContents(product)}
                {this.getExpert(product)}
                {this.getTeachingMaterials()}
                {auth && auth.isAuthenticated ?this.getQuiz() : ''}
                {auth && auth.isAuthenticated ? '' : ProductContent.getBenefits(product)}
            </>

        );

    }

}

export default ProductOnlineContent;