import React from 'react';
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {Field, reduxForm} from "redux-form";
import {profileRenderField} from "../../common/form";
import {connect} from "react-redux";
import {profileUserUpdate} from "../../actions/profile";
import ProfilePasswordForm from "./ProfilePasswordForm";
import Profiler from "./Profiler";
import {profilerFetchCategories} from "../../actions/profiler";

const mapStateToProps = state => ({
    ...state.profile,
    profilerData: state.profiler.profilerData,
    userData: state.auth.userData,
});

const mapDispatchToProps = {
    profileUserUpdate,
    profilerFetchCategories
};

class ProfileUserContainer extends React.Component {

    componentDidMount() {
        this.props.profilerFetchCategories();

        const {userData} = this.props;
        if (userData)
            this.loadDataForm(userData);

    }

    loadDataForm(data) {
        Object.entries(data).map((v) => {
            return this.props.change(v[0], v[1]);
        })
    }

    onSubmit(values) {
        const {profileUserUpdate} = this.props;
        return profileUserUpdate(
            values
        );
    }

    render() {
        const {form, handleSubmit, userData, isFetching, error} = this.props;
        if (isFetching || !userData)
            return (<Spinner animation="grow"/>);
//placeholdery są istotne, i przekazują tytul labela.
        return (
            <Container className="profile-container">
                <div className="profile-edit-container">
                    <h2>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="user">
                                <path id="Vector"
                                      d="M2.25 16.5H15.75C15.75 13.1863 12.728 10.5 9 10.5C5.27208 10.5 2.25 13.1863 2.25 16.5Z"
                                      stroke="#141B34" strokeWidth="1.5"/>
                                <path id="Vector_2"
                                      d="M12.375 4.875C12.375 6.73896 10.864 8.25 9 8.25C7.13604 8.25 5.625 6.73896 5.625 4.875C5.625 3.01104 7.13604 1.5 9 1.5C10.864 1.5 12.375 3.01104 12.375 4.875Z"
                                      stroke="#141B34" strokeWidth="1.5"/>
                            </g>
                        </svg>
                        <span>Moje dane</span>
                    </h2>
                    {/*{error && <div className="alert alert-danger">{error}</div>}*/}
                    <Form id={form} onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <Container>
                            <Row>
                                <Col className="profile-settings-column" lg={4}>
                                    <h4>Kontakt</h4>
                                    <Field name="name" type="text"
                                           component={profileRenderField} placeholder="Imię"/>
                                    <Field name="surname" type="text"
                                           component={profileRenderField} placeholder="Nazwisko"/>
                                    <Field name="phoneNumber" type="text"
                                           component={profileRenderField} placeholder="Telefon"/>
                                    <Field name="email" type="text"
                                           component={profileRenderField} placeholder="E-mail"/>
                                    <Field name="position" type="text"
                                           component={profileRenderField} placeholder="Stanowisko"/>

                                </Col>
                                <Col className="profile-settings-column" lg={4}>
                                    <h4>Adres</h4>
                                    <Field name="tin" type="text"
                                           component={profileRenderField} placeholder="NIP"/>
                                    <Field name="companyName" placeholder="Nazwa firmy" type="text"
                                           component={profileRenderField}/>
                                    <Field name="street" placeholder="Ulica" type="text"
                                           component={profileRenderField}/>
                                    <Row>
                                        <Col>
                                            <Field name="streetNumber" placeholder="Numer domu" type="text"
                                                   component={profileRenderField}/>
                                        </Col>
                                        <Col>
                                            <Field name="apartmentNumber" placeholder="Numer lokalu" type="text"
                                                   component={profileRenderField}/>
                                        </Col>
                                    </Row>
                                    <Field name="city" placeholder="Miasto" type="text"
                                           component={profileRenderField}/>
                                    <Field name="postCode" placeholder="Kod pocztowy" type="text"
                                           component={profileRenderField}/>
                                </Col>
                                <Col className="profile-settings-column" lg={4}>
                                    <h4>Płatnik</h4>
                                    <Field name="tin" type="text"
                                           component={profileRenderField} placeholder="NIP"/>
                                    <Field name="companyName" placeholder="Nazwa firmy" type="text"
                                           component={profileRenderField}/>
                                    <Field name="street" placeholder="Ulica" type="text"
                                           component={profileRenderField}/>
                                    <Row>
                                        <Col>
                                            <Field name="streetNumber" placeholder="Numer domu" type="text"
                                                   component={profileRenderField}/>
                                        </Col>
                                        <Col>
                                            <Field name="apartmentNumber" placeholder="Numer lokalu" type="text"
                                                   component={profileRenderField}/>
                                        </Col>
                                    </Row>
                                    <Field name="city" placeholder="Miasto" type="text"
                                           component={profileRenderField}/>
                                    <Field name="postCode" placeholder="Kod pocztowy" type="text"
                                           component={profileRenderField}/>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                    <div className={"profile-contact-box"}>
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"
                                 fill="none">
                                <path
                                    d="M2.77762 10.9424C1.8296 9.2893 1.37185 7.93948 1.09584 6.57121C0.687619 4.54758 1.62181 2.57081 3.16938 1.30947C3.82345 0.776381 4.57323 0.958521 4.96 1.6524L5.83318 3.21891C6.52529 4.46057 6.87134 5.08139 6.8027 5.73959C6.73407 6.39779 6.26737 6.93386 5.33397 8.00601L2.77762 10.9424ZM2.77762 10.9424C4.69651 14.2883 7.70784 17.3013 11.0576 19.2224M11.0576 19.2224C12.7107 20.1704 14.0605 20.6282 15.4288 20.9042C17.4524 21.3124 19.4292 20.3782 20.6905 18.8306C21.2236 18.1766 21.0415 17.4268 20.3476 17.04L18.7811 16.1668C17.5394 15.4747 16.9186 15.1287 16.2604 15.1973C15.6022 15.2659 15.0661 15.7326 13.994 16.666L11.0576 19.2224Z"
                                    stroke="#141B34" strokeWidth="1.5" strokeLinejoin="round"/>
                            </svg>
                            Jeśli zechcesz zmienić swoje dane teleadresowe, zadzwoń: 22 518 29 29
                        </p>
                        <ProfilePasswordForm/>

                    </div>
                </div>
                {/*<Container className={"profile-profiler-container"}>*/}
                {/*    <Row>*/}
                {/*        <h4>Moje kategorie szkoleń</h4>*/}
                {/*        <Profiler children={this.props}/>*/}
                {/*    </Row>*/}
                {/*</Container>*/}
                {/*<div id="profiler" className="profile-pass-btn-container">*/}
                {/*</div>*/}

                <div className="profile-edit-container">
                    <h2>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                stroke="#141B34" strokeWidth="1.5"/>
                            <path
                                d="M16 11.55V10.3C16 9.74772 15.5523 9.3 15 9.3H9.43705C9.0264 9.3 8.79084 8.83237 9.03527 8.50239L9.77778 7.5M8 12.45V13.7C8 14.2523 8.44772 14.7 9 14.7H14.563C14.9736 14.7 15.2092 15.1676 14.9647 15.4976L14.2222 16.5"
                                stroke="#141B34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>Mój abonament</span>
                    </h2>
                    <Container>
                        <Row className={"brands-container"}>
                            {userData.brands.some(item => item.brandId === 1) ?
                                <Col className="profile-settings-subscription-column oswiata" lg={6}>
                                    <h3>Educado Oświata</h3>
                                    <h5>Abonament</h5>
                                    {window.sessionStorage.getItem('voucher')!== null && window.sessionStorage.getItem('voucher') !== 'false'?
                                    <p>Dostęp dla: {window.sessionStorage.getItem('voucherLimit')} osób</p> : ''

                                    }
                                </Col> : ''
                            }
                            {userData.brands.some(item => item.brandId === 2) ?

                                <Col className="profile-settings-subscription-column kik" lg={6}>
                                    <h3>Educado Księgowość i kadry</h3>
                                    <h5>Abonament</h5>
                                    {window.sessionStorage.getItem('secondVoucher')!== null && window.sessionStorage.getItem('secondVoucher') !== 'false' ?
                                        <p>Dostęp dla: {window.sessionStorage.getItem('secondVoucherLimit')} osób</p> : ''

                                    }
                                </Col> : ''
                            }
                        </Row>
                    </Container>
                    <div className={"btn-help-container"}><a href={"/pomoc"} className={"btn btn-link btn-help"} title={"pomoc"}>dowiedz się więcej</a></div>
                </div>
            </Container>
        )
    }
}

export default reduxForm({
    form: 'profileUserSave',
})(connect(mapStateToProps, mapDispatchToProps)(ProfileUserContainer));