import React from "react";
import {Route, Switch} from "react-router";
import Help from "./Help";
import AboutUs from "./AboutUs";
import Partners from "./Partners";
import NotFound from "./NotFound";
import Contact from "./Contact";
import BecomeExpert from "./BecomeExpert";
import ClosedTraining from "./ClosedTraining/ClosedTraining";
import Offer from "./Offer";
import Terms from "./Terms";
import SchoolMulti from "./schoolMulti/SchoolMulti";
import Index from "../Index";
import multiThx from "./schoolMulti/multiThx";

class Static extends React.Component {

    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route path="/pomoc" component={Help}/>
                    <Route path="/o-platformie" component={AboutUs}/>
                    <Route path="/wspolpraca" component={Partners}/>
                    <Route path="/zostan-ekspertem" component={BecomeExpert}/>
                    <Route path="/oswiata/plan-dla-szkoly" component={()=><SchoolMulti brand={1} />}/>
                    <Route path="/ksiegowosc-i-kadry/plan-dla-biura" component={()=><SchoolMulti brand={2} />}/>
                    <Route path="/kontakt" component={Contact}/>
                    <Route path="/szkolenia-dedykowane" component={ClosedTraining}/>
                    <Route path="/oswiata/abonament" component={()=><Offer brand={1} category={71}/> }/>
                    <Route path="/ksiegowosc-i-kadry/abonament/:id?" component={()=><Offer brand={2} category={13}/>}/>
                    <Route path="/subusers-offer/thx" component={multiThx}/>
                    <Route path="/regulamin" component={Terms}/>
                    <Route component={NotFound} status={404}/>
                </Switch>
            </React.Fragment>
        )
    }

}
export default Static;