import React, {useContext} from 'react';
import {Accordion, AccordionContext, Button, Card, Col, Container, Row, useAccordionToggle} from "react-bootstrap";
import {convertMetaDescription, convertMetaTitle} from "../../common/utils";
import Helmet from "react-helmet";

function ContextAwareToggle({children, eventKey, callback}) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button
            type="button"
            className={isCurrentEventKey ? 'active' : ''}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
}

const Help = () => {

    return (
        <>
            <Helmet>
                <title>{convertMetaTitle('pomoc')}</title>
                <meta name={"description"} content={convertMetaDescription("Pomoc")}/>
            </Helmet>
            <Container className={"help-container"} fluid>
                <Row className={"static-main-badge"}>
                    <Container>
                        <h1>Pomoc</h1>
                    </Container>
                </Row>
                <Row className={"static-second-badge"}>
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <h3>Sprawdź poniżej, czy przypadkiem nie odpowiedzieliśmy już na nurtujące Cię
                                    pytanie.</h3>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row className={"help-accordion-section"}>
                    <Container>
                        <Row>
                            <Accordion className={"col-lg-9"} defaultActiveKey="0">
                                <h4>PŁATNOŚCI I ABONAMENT EDUCADO</h4>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="0">
                                            W jaki sposób mogę uzyskać dostęp do Educado?
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>Dostęp do platformy Educado można uzyskać:
                                            <ul>
                                                <li>dokonując zakupu wybranego abonamentu na stronie
                                                    educado.pl/abonament,
                                                </li>
                                                <li>dzwoniąc pod numer 22 518 29 29 i składając zamówienie w Centrum
                                                    Obsługi
                                                    Klienta.
                                                </li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="1">
                                            Jak wyglądają ceny za dostęp do Educado?
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>Przy zakupie autoodnawialnego abonamentu na 1 miesiąc, koszt brutto
                                            to 99 zł brutto. Koszt autoodnawialnego dostępu 3-miesięcznego to 327 zł
                                            brutto, a autoodnawialnego dostępu 6-miesięcznego 594 zł
                                            brutto.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="2">
                                            Co to znaczy, że dostęp autoodnawialny?
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body>Wykupienie autoodnawialnego dostępu oznacza, że po
                                            zakończeniu okresu trwania abonamentu, odnowi się on automatycznie.
                                            Automatycznie zostanie również wystawiona nowa faktura na kolejny okres
                                            rozliczeniowy. Z dostępu autoodnawialnego można zrezygnować w dowolnym
                                            momencie.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="4">
                                            Czy wszystkie szkolenia online na Educado są płatne?
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="4">
                                        <Card.Body>Z zasady wszystkie materiały szkoleniowe dostępne na Educado są
                                            dostępne
                                            wyłącznie w ramach płatnego dostępu. Wybrane materiały szkoleniowe są
                                            wyjątkowo
                                            udostępniane przez Redakcję Educado bezpłatnie.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="5">
                                            Czy z Educado mogę korzystać wraz z koleżanką?
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="5">
                                        <Card.Body>W Educado konto jest przypisane do konkretnej osoby. Na koncie
                                            Użytkownika zapisywane sa dane na temat ulubionych, rozpoczętych i
                                            zakończonych
                                            szkoleń, informacje o wynikach wykonanych testów wiedzy oraz imienne
                                            certyfikaty.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="6">
                                            Czy zaraz po zakupie otrzymuję dostęp do Educado?
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="6">
                                        <Card.Body>Tak, Educado jest dostępne zaraz po dokonaniu zakupu na
                                            educadol.pl/abonament; złożeniu zamówienia w Centrum Obsługi Klienta (22 518
                                            29
                                            29) lub złożeniu zamówienia poprzez zewnętrznych konsultantów.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="7">
                                            W jaki sposób dowiem się, że mam już dostęp do Educado?
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="7">
                                        <Card.Body>Każda osoba, która kupi lub zamówi dostęp do Educado, otrzyma
                                            wiadomość
                                            e-mail z informacjacmi na temat aktywacji dostępu do Educado.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="8">
                                            Czy mogę kupić dostęp telefonicznie? </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="8">
                                        <Card.Body>Aby dokonać zamówienia telefonicznego, wystarczy zadzwonić pod numer:
                                            22
                                            518 29 29.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="10">
                                            Czy mogę zamówić dostęp dla wielu osób tzw.
                                            wielostanowiskowy? </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="10">
                                        <Card.Body>Tak. Jest to oferta autoodnawialna na 12 miesięcy. W celu zamówienia
                                            dostępu zapraszamy do wypełnienia formularza. Nasi konsultanci skontaktują
                                            się z Państwem bezpośrednio w celu przygotowania oferty dopasowanej do
                                            Państwa potrzeb.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="11">
                                            Ile kosztuje dostęp do Educado? </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="11">
                                        <Card.Body>Na 1 miesiąc - 99 zł brutto (abonament odnawiany automatycznie co
                                            miesiąc, co miesiąc nowa fv)<br/>
                                            Na 3-miesiące - 327 zł brutto (abonament odnawiany automatycznie co 3
                                            miesiące, co 3 miesiące nowa faktura)<br/>
                                            Na 6-miesięcy - 594 zł brutto (abonament odnawiany automatycznie co 6
                                            miesiący, co 6 miesiący nowa faktura)
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="12">
                                            Czy mogę samodzielnie zmienić dane płatnika do fv? </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="12">
                                        <Card.Body>Samodzielna zmiana danych płatnika oraz adresowych nie jest możliwa.
                                            W
                                            celu zmiany danych adresowych lub płatnika należy skontaktować się z Centrum
                                            Obsługi Klienta Tel. 22 518 29 29</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="13">
                                            Jakie są możliwości płatności?</ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="13">
                                        <Card.Body>
                                            <ul>
                                                <li> Płatność przelewem</li>
                                                <li>Płatności elektroniczne i płatności kartą płatniczą za pośrednictwem
                                                    serwisu PayU
                                                </li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="14">
                                            W jaki sposób otrzymam fakturę?</ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="14">
                                        <Card.Body>Faktury VAT będą przesyłane w formie elektronicznej na adres email
                                            wskazany przez Użytkownika przy składaniu zamówienia, chyba, że Użytkownik
                                            zrezygnował z tej opcji i przy składaniu zamówienia wybrał od razu opcję
                                            faktury
                                            papierowej na wskazany przez niego adres do korespondencji.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="15">
                                            Co się stanie, jeśli zapomnę opłacić faktury?</ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="15">
                                        <Card.Body>Jeśli zapomnisz opłacić fakturę, na pewno Ci o niej przypomnimy.
                                            Jeśli
                                            tylko spostrzeżesz, że o tym zapomniałeś/aś zadzwoń do Centrum Obsługi
                                            Klienta
                                            Tel. 22 518 29 29 i poproś o pomoc. Zgodnie z regulaminem Educado zastrzega
                                            sobie prawo do zablokowania dostępu do usług, a następnie rozwiązania umowy
                                            o
                                            świadczenie usług płatnych ze skutkiem natychmiastowym, jeśli Użytkownik,
                                            mimo
                                            wezwania do zapłaty, zalega z płatnością abonamentu.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <h4>ANULOWANIE SUBSKRYPCJI EDUCADO</h4>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="16">
                                            W jaki sposób anulować autoodnawialną subskrypcję miesięczną Educado?
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="16">
                                        <Card.Body>Umowa o świadczenie Usług płatnych zawierana jest na czas określony
                                            zgodny z ofertą przyjętą przez Użytkownika.
                                            W przypadku autoodnawialnego abonamentu miesięcznego, okres obowiązywania
                                            ulega automatycznemu przedłużeniu
                                            na kolejny taki sam okres, o ile Użytkownik nie wyrazi na piśmie przeciwnego
                                            oświadczenia woli najpóźniej na 30 dni
                                            przed upływem terminu obowiązywania Umowy, przy czym w zakresie Użytkowników
                                            o statusie Konsumenta uprawnieni
                                            są oni do oświadczenia w dowolnej formie, że nie chcą jej przedłużać
                                            najpóźniej do ostatniego dnia jej obowiązywania,
                                            a ponad to są oni uprawnieni do odstąpienia od tej Umowy ze skutkiem od dnia
                                            takiego przedłużenia, składając stosowne
                                            oświadczenie w terminie 14 dni od dnia otrzymania faktury za taki
                                            przedłużony okres Warunki Umowy, na jakich będzie
                                            ona kontynuowana w wyniku automatycznego przedłużenia okresu jej
                                            obowiązywania, nie mogą być mniej korzystne niż
                                            przewidziane w ramach pierwotnej Umowy, a w szczególności nie może się to
                                            wiązać dla Użytkownika będącego
                                            Konsumentem z jakimikolwiek ponoszonymi przez niego zwiększonymi
                                            kosztami.<br/><br/>
                                            Zgodnie z ustawą z dnia 30.05.2014 r. o prawach konsumenta (Dz.U. z 2014 r.
                                            poz. 827 z późn. zm.) Użytkownikom
                                            będącymi Konsumentami przysługuje prawo do odstąpienia od Umowy bez podania
                                            przyczyny i bez ponoszenia
                                            dodatkowych kosztów, w terminie 14 dni od daty Rejestracji. Do odstąpienia
                                            od Umowy konieczne jest złożenie
                                            Administratorowi stosownego oświadczenia. W tym celu można skorzystać z
                                            wzoru oświadczenia które znajduje się tutaj
                                            (kliknij aby pobrać: <a
                                                href="http://regulamin.wip.pl/odstapienie/formularz_odstapienia.docx">http://regulamin.wip.pl/odstąpienie/formularz_odstapienia.docx</a>)
                                            i wysłać stosowne oświadczenie na
                                            adres: <a href="mailto:cok@wip.pl">cok@wip.pl</a>, stosownego.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="17">
                                            W jaki sposób zrezygnować z abonamentu na 12 miesięcy, który nie jest
                                            autoodnawialny?
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="17">
                                        <Card.Body>Osoba, która kupiła dostęp na 12 miesięcy, może zrezygnować z niego w
                                            ciągu 14 dni kalendarzowych od zakupu/zamówienia.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="18">
                                            Czy jeśli kiedyś znów będę potrzebować dostępu do Educado, to czy muszę się
                                            od
                                            nowa rejestrować?
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="18">
                                        <Card.Body>Nawet jeśli skończy Ci się dostęp do Educado, będziesz mógł/mogła
                                            zalogować się do swojego konta na educado.pl, ale nie będziesz mógł/mogła:
                                            <ul>
                                                <li>odtwarzać zapamiętanych wideo</li>
                                                <li>odtwarzać nowych wideo</li>
                                                <li>pobierać materiałów szkoleniowych</li>
                                                <li>wykonywać testów</li>
                                                <li>generować i pobierać certyfikatów</li>
                                            </ul>
                                            Będziesz mógł/mogła jedynie odtwarzać wybrane bezpłątne szkolenia oraz
                                            przeglądać inaformacje o dodanych szkoleniach.
                                            Jeśli zechcesz ponownie wykupić dostęp, wystarczy, że się zalogujesz i w
                                            zakładce educado.pl/abonament wybierzesz swoją ofertę i dokonasz zakupu lub
                                            zadzwonisz z zamowieniem do Centrum Obsługi Klienta pod nr telefonu: 22 518
                                            29
                                            29
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <h4>INFORMACJE OGÓLNE</h4>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="19">
                                            Co zrobić, jeśli zapomniałam/em hasła do Educado?
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="19">
                                        <Card.Body>Wystarczy, że będziesz postępować zgodnie ze wskazówkami widocznymi
                                            na
                                            stronie logowania.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="20">
                                            Czy mogę korzystać z Educado za granicą?
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="20">
                                        <Card.Body>Aby korzystać z Educado wystarczy mieć wykupiony abonament i dostęp
                                            do
                                            internetu, więc jeśli tylko spełniasz te kryteria, możesz korzystać z
                                            Educado za
                                            granicą.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="21">
                                            Czy jest możliwość kontaktu z ekspertem?
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="21">
                                        <Card.Body>Jeśli chciałbyś zapytać eksperta o zawartość merytoryczną szkolenia,
                                            możemy Ci w tym pomóc. Wyślij do nas e-maila na adres <a
                                                href="mailto:redakcja@educado.pl">redakcja@educado.pl</a>. W treści
                                            e-maila
                                            wskaż eksperta, link do szkolenia oraz zadaj swoje pytanie. Postaramy się
                                            pomóc
                                            uzyskać odpowiedź eksperta jak najszybciej. Należy jednak pamiętać, że nasi
                                            eksperci sa mocno zabiegani i może to trochę potrwać.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="22">
                                            Czy muszę wyrazić zgodę na kontakt marketingowy?
                                        </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="22">
                                        <Card.Body>Wyrażenie zgody marketingowej nie jest obowiązkowe, ale dzięki jej
                                            zaznaczeniu będziesz mieć szansę dowiedzieć się jako pierwszy o naszych
                                            najnowszych projektach.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <h4>Treść</h4>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="23">
                                            Co mam zrobić, jeśli zauważę gdzieś błąd merytoryczny? </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="23">
                                        <Card.Body>Jeśli uważasz, że szkolenie lub materiał edukacyjny zawiera błąd,
                                            napisz
                                            do nas: <a href="mailto:redakcja@educado.pl">redakcja@educado.pl</a><br/>
                                            Sprawdzimy i poprawimy potrzebne elementy.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <h4>INFORMACJE TECHNICZNE</h4>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="24">
                                            Jakie są wytyczne techniczne, dzięki którym mogę ze spokojem korzystać z
                                            platformy?</ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="24">
                                        <Card.Body>Administrator nie gwarantuje i nie odpowiada za to, że każdy wariant
                                            konfiguracyjny sprzętu elektronicznego posiadany przez
                                            Użytkownika, umożliwi korzystanie z Platformy. Minimalne wymagania
                                            techniczne umożliwiające korzystanie z Platformy, to
                                            oprogramowanie w celu korzystania z plików pdf, doc, docx, xls, xlsx, ppt,
                                            przeglądarka internetowa zgodna ze standardem
                                            Microsoft Internet Explorer w wersji nie niższej niż 5.0, niezablokowana
                                            możliwość oglądania streamu video z Vimeo, przy
                                            czym za problemy wynikające ze stosowania przeglądarek niespełniających tego
                                            wymogu Administrator nie ponosi
                                            odpowiedzialności.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="25">
                                            Co mam zrobić, jeśli mam problem z zalogowaniem?</ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="25">
                                        <Card.Body>Zadzwoń pod numer <strong>22 518 29 29</strong>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="26">
                                            Co mam zrobić, jeśli mam problem z odtwarzeniem
                                            szkoleń?</ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="26">
                                        <Card.Body>Zadzwoń pod numer <strong>22 518 29 29</strong>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="27">
                                            Co powinnam/ powinienem zrobić, jeśli nie mogę pobrać materiałów powiązanych
                                            ze
                                            szkoleniem?</ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="27">
                                        <Card.Body>Zadzwoń pod numer <strong>22 518 29 29</strong>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="28">
                                            Czy mogę oglądać szkolenia na urządzeniu mobilnym?</ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="28">
                                        <Card.Body>Strona educado.pl jest responsywna, co oznacza, że możesz korzystać z
                                            Educado na urządeniach mobilnych o różnej przekątnej ekranu.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="29">
                                            Co mam zrobić, jeśli w szkoleniu nie ma dźwięku?</ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="29">
                                        <Card.Body>Sprawdź czy masz włączony głośnik zewnętrzny, głośnik wewnętrzny,
                                            słuchawki lub głośnik w playerze. Jeśli wszystkie elementy są w jak
                                            najlepszym
                                            porządku, zadzwoń pod numer <strong>22 518 29 29</strong>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="30">
                                            Czy mogę korzystać z Educado mając wyłączony internet?</ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="30">
                                        <Card.Body>Jednym z warunków korzystania z educado jest dostęp do internetu,
                                            więc
                                            niestety w miejscach, w których nie masz dostępu do internetu nie będziesz
                                            mógł/mogła korzystać z Educado.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <h4>TESTY I CERTYFIKATY</h4>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="31">
                                            Czy muszę oglądać całe szkolenie, aby wykonać test?</ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="31">
                                        <Card.Body>Wystarczy, że będziesz miał/a właczone szkolenie przez ok. 3 min.
                                            Wtedy
                                            aktywuje się zakładka z testami i możesz wykonac test bez oglądania całego
                                            szkolenia.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="32">
                                            Czy mogę zmienić dane na certyfikacie?</ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="32">
                                        <Card.Body>Dane na certyfikacie są przypisane do konta użytkownika oraz
                                            powiązane ze
                                            szkoleniem. Nie można ich zmieniać.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <h4>reklamacje</h4>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="33">
                                            Jak zgłosić reklamację?</ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="33">
                                        <Card.Body>Wszelkie reklamacje dotyczące funkcjonowania Educado należy zgłaszać
                                            drogą elektroniczną na adres e-mail: <a
                                                href="mailto:cok@wip.pl">cok@wip.pl</a> z podaniem opisu reklamacji i
                                            przyczyn jej zgłoszenia oraz podaniem danych identyfikujących Użytkownika
                                            wnoszącego reklamację. Każda reklamacja złożona przez Użytkownika powinna
                                            zawierać następujące elementy:<br/>
                                            <ul>
                                                <li>imię i nazwisko Użytkownika, firma (nazwa) Użytkownika;</li>
                                                <li>adres e-mail;</li>
                                                <li>wskazanie Usługi, z którą wiąże się reklamacja;</li>
                                                <li>wskazanie okoliczności uzasadniających reklamację;</li>
                                                <li>określenie zakresu żądania przez Użytkownika składającego
                                                    reklamację.
                                                </li>
                                            </ul>
                                            Reklamacja jest rozpatrywana w terminie 14 Dni Roboczych od daty jej
                                            otrzymania
                                            w prawidłowy sposób, określony w Regulaminie, termin ten liczy się od dnia
                                            dostarczenia uzupełnionej reklamacji.

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <h4>SZKOLENIA DEDYKOWANE</h4>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="34">
                                            Czy Educado może zorganizować szkolenie dedykowane dla mojej
                                            firmy?</ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="34">
                                        <Card.Body>Zespół Educado chętnie zorganizuje szkolenie dedykowane dla Twojej
                                            firmy.
                                            Więcej informacji na ten temat znajdziesz na stronie <a
                                                href="/szkolenia-dedykowane">educado.pl/szkolenia-dedykowane</a>.

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <h4>partnerzy</h4>
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle as={Button} variant="link" eventKey="35">
                                            Chcę zostać Partnerem wydarzeń organizowanych przez Educado, co
                                            powinnam/ienem
                                            zrobić?</ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="35">
                                        <Card.Body>Wszystkie informacje na ten temat znajdziesz tutaj <a
                                            href="/wspolpraca">educado.pl/wspolpraca.</a>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Row>
                    </Container>
                </Row>
                <Row className={"help-contact-badge"}>
                    <Container>
                        <Row>
                            <Col sm={6} className={"help-contact-left"}><h4>Potrzebujesz więcej informacji?</h4><p>Jeśli
                                nie
                                znalazłaś/eś odpowiedzi na swoje pytania w zakładce „Pomoc”, napisz lub zadzwoń do nas.
                                Postaramy się pomóc w każdej sprawie związanej dotyczącej korzystania z naszej platformy
                                szkoleniowej.
                            </p></Col>
                            <Col sm={6} className={"help-contact-right"}>
                                <Button href={"https://kontakt.wip.pl/"} className={"btn-contact"}>Napisz do
                                    nas</Button>
                                <p>Zadzwoń do nas<span> 22 518 29 29</span></p>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>
        </>
    );

}

export default Help;