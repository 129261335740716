import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Helmet from "react-helmet";
import {convertMetaDescription, convertMetaTitle} from "../../common/utils";

const Partners = props => {

    return (
        <>
            <Helmet>
                <title>{convertMetaTitle('współpraca partnerska')}</title>
                <meta name={"description"} content={convertMetaDescription("Zostań partnerem Educado")}/>
            </Helmet>
            <Container className={"partners-container"} fluid>
                <Row className={"static-main-badge"}>
                    <Container>
                        <h1>Zostań partnerem Educado</h1>
                    </Container>
                </Row>
                <Row className={"static-second-badge"}>
                    <Container>
                        <Row>
                            <Col lg={7}>
                                <h3>Educado wspiera specjalistów z różnych branż w ich rozwoju zawodowym, pozwala
                                    poszerzyć
                                    i zaktualizować wiedzę oraz pozyskać praktyczne wskazówki.</h3>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row className={"partners-introduction-section"}>
                    <Container className={"partners-introduction-content"}>
                        <Row>
                            <Col md={7} className={"partners-introduction-left"}>
                                <h3>Dlaczego warto zostać partnerem Educado ?</h3>
                                <p>Zostając Partnerem wydarzeń organizowanych przez Educado możesz pomóc poszerzać
                                    wiedzę
                                    wybranej grupie zawodowej.</p>
                                <p>Twoje wsparcie może pomóc zbudowaać nową grupę lojalnych klientów, którzy będą
                                    wspierać
                                    Twój biznes.</p>
                                <p>Dołącz do grona Partnerow
                                    Educado i razem z nami wspieraj rozwój swoich klientów w ramach wydarzeń
                                    organizowanych
                                    przez Educado.</p>
                            </Col>
                            <Col md={5} className={"partners-introduction-right"}>
                                <ul className={"green-list"}>
                                    <li>Rozwój Twojego biznesu</li>
                                    <li>Pozyskanie nowych klientów</li>
                                    <li>Zbudowanie wizerunku eksperta</li>
                                    <li>Nowy kanał dla content marketingu</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                    <Container className={"stage-container"}>
                        <h3> Etapy współpracy</h3>
                        <Row>
                            <Col lg={4} md={6} className={"stage-item stage-item-1"}>
                                <div className="item-badge"></div>
                                <div className="item-title">Wstępne ustalenia</div>
                                <div className="item-content">Ustalamy: zakres działań, formułę wydarzenia (webinarium,
                                    debata, transmisja, konferencja, kongres, itp.), harmonogram, termin, temat,
                                    program, wybieramy eksperta/ów
                                </div>
                            </Col>
                            <Col lg={4} md={6} className={"stage-item stage-item-2"}>
                                <div className="item-badge"></div>
                                <div className="item-title">Promocja</div>
                                <div className="item-content">Na podstawie zebranych informacji, materiałów
                                    brandingowych,
                                    przygotowujemy materiały promocyjne oraz mediaplan, które akceptujemy z Partnerem.
                                    Na
                                    bieżąco informujemy o wynikach promocji.
                                </div>
                            </Col>
                            <Col lg={4} md={6} className={"stage-item stage-item-3"}>
                                <div className="item-badge"></div>
                                <div className="item-title">Przygotowanie merytoryczne</div>
                                <div className="item-content">Jeśli wydarzenie jest realizowane przy wsparciu ekspertów
                                    poleconych przez Partnera, pomagamy im przygotować się do realizacji. Przeprowadzamy
                                    testy techniczne wydarzenia oraz próby.
                                </div>
                            </Col>
                            <Col lg={4} md={6} className={"stage-item stage-item-4"}>
                                <div className="item-badge"></div>
                                <div className="item-title">Wydarzenia</div>
                                <div className="item-content">Wydarzenia mogą odbywać się zdalnie lub stacjonarnie. Z
                                    każdym
                                    Partnerem ustalamy scenariusz realizacyjny wydarzenia. W przypadku webinariów
                                    moderujemy
                                    sesje pytań. Wydarzenia są obrandowane.
                                </div>
                            </Col>
                            <Col lg={4} md={6} className={"stage-item stage-item-5"}>
                                <div className="item-badge"></div>
                                <div className="item-title">Po wydarzeniu</div>
                                <div className="item-content">Uczestnicy wydarzenia mogą otrzymać certyfikat z logo
                                    Partnera
                                    i prezentację wraz z dodatkowym komunikatem od Partnera. Po zakończeniu webinarium
                                    informujemy o frekwencji.
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row className={"partners-blue-section"}>
                    <Container>
                        <Row>
                            <Col md={{span: 5, offset: 1}} className={"partners-item"}>
                                <h3>Zakres naszych działań</h3>
                                <ul>
                                    <li>Przygotowania wstępne: ustalenie tematu, programu, eksperta/ów, pozyskanie
                                        materiałów od Partnera
                                    </li>
                                    <li>Promocja wydarzenia: przygotowanie materiałów promocyjnych i mediaplanu,
                                        realizacja
                                        zaakceptowanej promocji, pozyskiwanie uczestników wydarzenia
                                    </li>
                                    <li>Przygotowanie do wydarzenia: testy techniczne, ustalenia z ekspertami</li>
                                    <li>Realizacja wydarzenia: wynajem studia, produkcja wydarzenia, realizacja
                                        transmisji,
                                        moderacja, mobsługa chat, makijaż (dodatkowy element do wyceny)

                                    </li>
                                    <li>Obsługa uczestników: korespondencja w sprawach organizacyjnych, wysyłka
                                        materiałów
                                        powiązanych z wydarzeniem
                                    </li>
                                    <li>Podsumowanie i rozliczenie wydarzenia</li>
                                </ul>
                            </Col>
                            <Col md={{span: 5, offset: 1}} className={"partners-item"}>
                                <h3>Sprawy do ustalenia</h3>
                                <ul>
                                    <li>Zakres umowy</li>
                                    <li>Formuła wydarzenia</li>
                                    <li>Ekspert/ci</li>
                                    <li>Prowadzący debatę</li>
                                    <li>Odpowiedzi na pytania merytoryczne po wydarzeniu w przypadku bardzo dużej liczby
                                        pytań w
                                        trakcie wydarzenia, na które nie udało odpowiedzieć się ekspetowi/om
                                    </li>
                                </ul>
                            </Col>
                            <Col md={{span: 5, offset: 1}} className={"partners-item"}>
                                <h3>Zakres działań parntera</h3>
                                <ul>
                                    <li>Potwierdzenie formuły wydarzenia</li>
                                    <li>Podpisanie umowy</li>
                                    <li>Przekazanie materiałow brandingowych</li>
                                    <li>Przekazanie danych kontaktowych do ekspertów Partnera</li>
                                    <li>Zatwierdzenie materiałow promocyjnych i mediaplanu</li>
                                    <li>Udział w testach technicznych</li>
                                    <li>Rozliczenie kosztów</li>
                                </ul>
                            </Col>
                            <Col md={{span: 5, offset: 1}} className={"partners-item"}>
                                <h3>Potrzebne materiały</h3>
                                <ul>
                                    <li>Materiały brandingowe – logo (png, jpg, eps) oraz brand manual (czcionki,
                                        kolory,
                                        itp.)
                                    </li>
                                    <li>Zdjęcie eksperta/ów poleconych przez Partnera</li>
                                    <li>Bio eksperta/ów poleconych przez Partnera</li>
                                    <li>Scenariusz wydarzenia</li>
                                    <li>Prezentacja ppt – jeśli jest potrzebna w trakcie wydarzenia</li>
                                    <li>Materiały dodatkowe dla uczestników wydarzenia</li>
                                    <li>Informacje do przekazania uczestnikom na chat w trakcie webinarium</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row className={"partners-contact-section"}>
                    <Container>
                        <Row>
                            <Col md={12} className={"partners-contact-content text-center"}>
                                <h4>Chcesz dowiedzieć się więcej?<br/>
                                    Skontaktuj się z nami: <a href={"mailto:reklama@wip.pl"}>reklama@wip.pl</a>
                                </h4>
                                {/*<h4> e - mail:<a href="mailto:mglegola@wip.pl"> mglegola@wip.pl</a></h4>*/}
                            </Col>
                            {/*<Col className={"partners-contact-img"}>*/}
                            {/*    <img src={"/monika-glegola.png"} alt={"Monika Glegoła"}/>*/}
                            {/*    <p>Monika Glegoła<span>Koordynator ds. konferencji</span></p>*/}
                            {/*</Col>*/}
                        </Row>
                    </Container>
                </Row>
            </Container>
        </>
    );

}
export default Partners;