import React from 'react';
import {slide as Menu} from 'react-burger-menu'
import Navbar from "react-bootstrap/Navbar";
import {Button, Container, Form, FormControl, Modal, Nav, NavDropdown, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import Auth from "./Auth";
import {categoryAllFetch, categoryDescendantsFetch, categoryFetch} from "../actions/category";
import {connect} from "react-redux";
import {CATEGORY_MAIN_ID} from "../constants";
import {strNormalize} from "../common/utils";
import {multiTableGetSecondVoucher, multiTableGetVoucher} from "../actions/multiTable";

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    userData: state.auth.userData,
    voucher: state.multiTable.voucher,
    secondVoucher: state.multiTable.voucher
});

const mapDispatchToProps = {multiTableGetVoucher,multiTableGetSecondVoucher }

class HeaderMobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showSearch: false,
            mainCategory: false
        }
    }

    componentDidMount() {
        const {userData, multiTableGetVoucher, multiTableGetSecondVoucher} = this.props;
        if( userData && window.sessionStorage.getItem('voucher') ===  null) {
            multiTableGetVoucher();
        }
        if ( userData && window.sessionStorage.getItem('secondVoucher') ===  null) {
            multiTableGetSecondVoucher()
        }
    }


    showModal(search = false) {
        if (search) {
            this.setState({showSearch: true, show: false});
        } else {
            this.setState({show: true, showSearch: false});
        }
    }

    hideModal(search = false) {
        if (search) {
            this.setState({showSearch: false});
        } else {
            this.setState({show: false});
        }
    }


    getCategories(item) {
        return (
            <div className={"items-wrapper"}>
                <a href={`/${item}/szkolenia`}>
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>Szkolenia VOD</a>
                <a href={`/${item}/webinary/webinary-live-z-chatem`}> <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>Webinary LIVE</a>
                <a href={`/${item}/webinary/webinary-bezplatne`}> <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>Webinary</a>
                <a href={`/${item}`}> <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>Wszystko</a>
            </div>

        )
    }


    renderUser() {
        const {userData, userLogout} = this.props;
        const voucher = window.sessionStorage.getItem('voucher');
        const secondVoucher = window.sessionStorage.getItem('secondVoucher');
        if (null === userData) {
            return (<i className="fas fa-spinner fa-spin"/>);
        }
        return (
            <>
                <Button className="login-btn" onClick={this.showModal.bind(this, false)}><FontAwesomeIcon
                    icon={faUserCircle}/></Button>
                <Modal className="profile-modal" id={"profile-modal"} show={this.state.show}
                       onHide={this.hideModal.bind(this, false)}>
                    <Container className="modal-content-container">
                        <Row>
                            <Modal.Header closeButton>
                                <div className={"menu-mobile-category-badge"}><FontAwesomeIcon
                                    icon={faUserCircle}/> Moja strefa
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Container>
                                    <Row>
                                        <Nav className="flex-column user-info"
                                             title={`${userData.name} ${userData.surname}`}>
                                            <Nav.Item
                                                className="user-data">{userData.name} {userData.surname}</Nav.Item>
                                            <Nav.Link href={`/profile#favourite`}>Zapisane szkolenia</Nav.Link>
                                            <Nav.Link href={`/profile#started`}>Rozpoczęte szkolenia</Nav.Link>
                                            <Nav.Link href={`/profile#finished`}>Ukończone szkolenia</Nav.Link>
                                            {voucher === 'true' || secondVoucher === 'true' ?
                                            <Nav.Link href={`/profile`}>Panel Administratora</Nav.Link> : ''

                                        }
                                            <Nav.Link href={`/profile/edit`}>Ustawienia konta</Nav.Link>
                                            <Nav.Item href="#" className="logout"
                                                      onClick={userLogout}>Wyloguj</Nav.Item>
                                        </Nav> </Row>
                                </Container>
                            </Modal.Body>
                        </Row>
                    </Container>
                </Modal>
            </>
        );
    }

    renderSearch() {

        return (
            <React.Fragment>
                <Button className="login-btn search-btn" onClick={this.showModal.bind(this, true)}><FontAwesomeIcon
                    icon={faSearch}/></Button>
                <Modal className="login-modal" id={"search-modal"} show={this.state.showSearch}
                       onHide={this.hideModal.bind(this, true)}>
                    <Container className="modal-content-container">
                        <Row>
                            <Modal.Header closeButton>
                                <img alt="logo" src="/logo.svg"/>
                            </Modal.Header>
                            <Modal.Body>
                                <Container>
                                    <Row>
                                        <Form className="search-form" action="/szukaj" method="get">
                                            <FormControl name="phraze" type="text" placeholder="Czego szukasz?"
                                                         className=""/>
                                            <Button type="submit" className="login-btn"
                                                    variant="primary">Szukaj</Button>
                                        </Form>
                                    </Row>
                                </Container>
                            </Modal.Body>
                        </Row>
                    </Container>
                </Modal>
            </React.Fragment>
        );
    }

    render() {
        const {isAuthenticated, isFetching} = this.props;

        return (
            <Navbar id="mobile-menu">
                <Container className="mobile-menu-container">
                    <div id="outer-container">
                        <Menu pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
                            <main id="page-wrap">
                                {/*<div className="menu-mobile-category-badge">Katalog szkoleń</div>*/}
                                {isAuthenticated ? '' :
                                    <div className={"mobile-menu-section"}>
                                        <h2>Kup dostep</h2>
                                        <div className={"items-wrapper"}>
                                            <a href={"/oswiata/abonament"} title={"Oferta Oświata"}> <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>Oświata</a>
                                            <a href={"/ksiegowosc-i-kadry/abonament"} title={"Oferta Księgowość i kadry"}> <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>Księgowość i kadry</a>

                                        </div>
                                    </div>}
                                <div className={"mobile-menu-section"}>
                                    <h2 className={"oswiata"}>Oświata</h2>
                                    {this.getCategories('oswiata')}
                                </div>
                                <div className={"mobile-menu-section"}>
                                    <h2 className={"kik"}>Księgowośc i kadry</h2>
                                    {this.getCategories('ksiegowosc-i-kadry')}
                                </div>
                                <div className={"mobile-menu-section"}>
                                    <h2>Współpraca</h2>
                                    <div className={"items-wrapper"}>
                                        <a href={"/szkolenia-dedykowane"} title={"Szkolenia dedykowane"}> <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>Szkolenia dedykowane</a>
                                        <a href={"/wspolpraca"} title={"Współpraca partnerska"}> <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>Współpraca partnerska</a>
                                        <a href={"/zostan-ekspertem"} title={"Zostań ekspertem"}> <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1.1665 7.49994H12.8332M12.8332 7.49994L6.99982 1.6665M12.8332 7.49994L6.99982 13.3332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>Zostań ekspertem</a>
                                    </div>
                                </div>
                            </main>
                        </Menu>
                    </div>
                    <Navbar.Brand className="logo-header" href="/">
                        <img style={{width: 130}} alt="logo Educado" src="/logo-white.svg"/>
                    </Navbar.Brand>
                    <div className="menu-mobile-right-icons">
                        {isAuthenticated ? this.renderUser() : <Auth/>}
                        {this.renderSearch()}
                    </div>
                </Container>
            </Navbar>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMobile)