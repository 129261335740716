import React from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";

const CategoryBanner = props => {
    const {auth, id} = props;

    function getContent(id) {
        switch (id) {
            case 71:
                return (
                    <div className={"index-banner-container"}>
                        <Row className={"index-banner-container-content"}>
                            <Col lg={3} className="index-banner headline">
                                <h1>Wejdź do świata szkoleń online dla pracowników oświaty!</h1>
                            </Col>
                            <Col lg={3} className="index-banner ">
                                <div className={"icon-wrapper"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35"
                                         fill="none">
                                        <path
                                            d="M2.9165 11.6667C2.9165 13.6234 14.738 18.9583 17.4998 18.9583C20.2616 18.9583 32.0832 13.6234 32.0832 11.6667C32.0832 9.7099 20.2616 4.375 17.4998 4.375C14.738 4.375 2.9165 9.7099 2.9165 11.6667Z"
                                            stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M8.75 16.042L10.5771 24.4993C10.7022 25.0787 10.9911 25.6199 11.4728 25.9651C14.7169 28.2898 20.2831 28.2898 23.5271 25.9651C24.009 25.6199 24.2979 25.0787 24.423 24.4993L26.25 16.042"
                                            stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M30.9894 13.8545C30.9894 13.2504 30.4997 12.7607 29.8956 12.7607C29.2916 12.7607 28.8019 13.2504 28.8019 13.8545H30.9894ZM28.8019 13.8545V24.0733H30.9894V13.8545H28.8019ZM28.8764 29.0748C29.0877 28.1855 29.4511 27.3728 29.8401 26.6117C30.1963 25.9143 30.6632 25.1076 30.913 24.4751L28.8783 23.6716C28.6787 24.1772 28.3445 24.7309 27.892 25.6164C27.4722 26.4382 27.0186 27.4308 26.7481 28.5692L28.8764 29.0748ZM33.0432 28.5692C32.7726 27.4308 32.3191 26.4382 31.8992 25.6164C31.4467 24.7309 31.1126 24.1772 30.913 23.6716L28.8783 24.4751C29.1281 25.1076 29.5949 25.9143 29.9512 26.6117C30.3401 27.3728 30.7035 28.1855 30.9149 29.0748L33.0432 28.5692ZM30.3556 29.5316H29.4357V31.7191H30.3556V29.5316ZM26.7481 28.5692C26.5683 29.3265 26.4602 30.3284 27.1737 31.0439C27.5078 31.379 27.9197 31.5363 28.2826 31.6168C28.6442 31.6971 29.041 31.7191 29.4357 31.7191V29.5316C29.1027 29.5316 28.8895 29.5109 28.7561 29.4813C28.624 29.452 28.6559 29.4326 28.7227 29.4995C28.8068 29.5838 28.808 29.6625 28.8039 29.6183C28.7975 29.5499 28.8025 29.3863 28.8764 29.0748L26.7481 28.5692ZM30.9149 29.0748C30.9888 29.3863 30.9938 29.5499 30.9873 29.6183C30.9833 29.6625 30.9844 29.5838 31.0686 29.4995C31.1354 29.4326 31.1673 29.452 31.0352 29.4813C30.9017 29.5109 30.6885 29.5316 30.3556 29.5316V31.7191C30.7502 31.7191 31.147 31.6971 31.5087 31.6168C31.8715 31.5363 32.2835 31.379 32.6176 31.0439C33.331 30.3284 33.223 29.3265 33.0432 28.5692L30.9149 29.0748Z"
                                            fill="#4AA5D8"/>
                                    </svg>
                                </div>
                                <p>Educado Oświata to platforma, na której znajdziesz <strong>setki webinarów i szkoleń
                                    online</strong> dla profesjonalistów z obszaru OŚWIATY i prawa oświatowego!</p>
                            </Col>
                            <Col lg={3} className="index-banner ">
                                <div className={"icon-wrapper"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35"
                                         fill="none">
                                        <path
                                            d="M18.229 32.0837H9.61152C7.35784 32.0837 5.56529 30.987 3.9558 29.4537C0.660996 26.3147 6.07059 23.8062 8.13379 22.5777C11.4142 20.6244 15.2927 19.9954 18.9582 20.6909"
                                            stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M22.604 9.47949C22.604 13.1039 19.6659 16.042 16.0415 16.042C12.4171 16.042 9.479 13.1039 9.479 9.47949C9.479 5.85513 12.4171 2.91699 16.0415 2.91699C19.6659 2.91699 22.604 5.85513 22.604 9.47949Z"
                                            stroke="#4AA5D8" strokeWidth="1.5"/>
                                        <path
                                            d="M27.2577 21.2595L28.2842 23.3296C28.4242 23.6176 28.7974 23.8941 29.1124 23.9471L30.9731 24.2587C32.1629 24.4587 32.4429 25.329 31.5854 26.1877L30.1389 27.6461C29.8941 27.8932 29.7599 28.3695 29.8357 28.7106L30.2498 30.516C30.5764 31.945 29.8241 32.4979 28.57 31.7509L26.826 30.7101C26.511 30.5218 25.992 30.5218 25.6712 30.7101L23.9272 31.7509C22.679 32.4979 21.9208 31.9392 22.2473 30.516L22.6615 28.7106C22.7373 28.3695 22.6031 27.8932 22.3581 27.6461L20.9116 26.1877C20.0601 25.329 20.3343 24.4587 21.5241 24.2587L23.3847 23.9471C23.6938 23.8941 24.0672 23.6176 24.2072 23.3296L25.2337 21.2595C25.7937 20.1362 26.7035 20.1362 27.2577 21.2595Z"
                                            stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <p><strong>Nasi eksperci to uznani specjaliści ds. oświaty,</strong> którzy pomogli w
                                    osiągnięciu sukcesów tysiącom nauczycieli
                                    i dyrektorów!</p>
                            </Col>
                            <Col lg={3} className="index-banner ">
                                <div className={"icon-wrapper"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35"
                                         fill="none">
                                        <path
                                            d="M17.5 2.5H12.5C8.40096 2.5 6.35145 2.5 4.89864 3.51727C4.36114 3.89362 3.89362 4.36114 3.51727 4.89864C2.5 6.35145 2.5 8.40096 2.5 12.5C2.5 16.599 2.5 18.6485 3.51727 20.1014C3.89362 20.6389 4.36114 21.1064 4.89864 21.4827C6.35145 22.5 8.40096 22.5 12.5 22.5H17.5C21.599 22.5 23.6485 22.5 25.1014 21.4827C25.6389 21.1064 26.1064 20.6389 26.4827 20.1014C27.5 18.6485 27.5 16.599 27.5 12.5C27.5 8.40096 27.5 6.35145 26.4827 4.89864C26.1064 4.36114 25.6389 3.89362 25.1014 3.51727C23.6485 2.5 21.599 2.5 17.5 2.5Z"
                                            stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"/>
                                        <path
                                            d="M18.125 27.5L17.7306 26.9764C16.8416 25.7961 16.6211 23.993 17.1835 22.5M11.875 27.5L12.2694 26.9764C13.1584 25.7961 13.3789 23.993 12.8165 22.5"
                                            stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"/>
                                        <path d="M8.75 27.5H21.25" stroke="#4AA5D8" strokeWidth="1.5"
                                              strokeLinecap="round"/>
                                        <path
                                            d="M10 17.5C12.2981 14.2557 17.6485 14.0788 20 17.5M17.5 10C17.5 11.3807 16.3807 12.5 15 12.5C13.6193 12.5 12.5 11.3807 12.5 10C12.5 8.61929 13.6193 7.5 15 7.5C16.3807 7.5 17.5 8.61929 17.5 10Z"
                                            stroke="#4AA5D8" strokeWidth="1.5" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <p><strong>Szkolenia dostępne są
                                    w wygodnym modelu subskrypcyjnym,</strong> który zapewnia możliwość zdobywania
                                    wiedzy gdzie i kiedy chcesz!</p>
                            </Col>
                        </Row>
                    </div>
                )
            case 13:
                return (
                    <div className={"index-banner-container"}>
                        <Row className={"index-banner-container-content"}>
                            <Col lg={3} className="index-banner headline">
                                <h1>Wejdź do świata szkoleń online dla pracowników księgowości i kadr!</h1>
                            </Col>
                            <Col lg={3} className="index-banner ">
                                <div className={"icon-wrapper"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35"
                                         fill="none">
                                        <path
                                            d="M2.9165 11.6667C2.9165 13.6234 14.738 18.9583 17.4998 18.9583C20.2616 18.9583 32.0832 13.6234 32.0832 11.6667C32.0832 9.7099 20.2616 4.375 17.4998 4.375C14.738 4.375 2.9165 9.7099 2.9165 11.6667Z"
                                            stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M8.75 16.043L10.5771 24.5003C10.7022 25.0797 10.9911 25.6209 11.4728 25.9661C14.7169 28.2908 20.2831 28.2908 23.5271 25.9661C24.009 25.6209 24.2979 25.0797 24.423 24.5003L26.25 16.043"
                                            stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M30.9894 13.8555C30.9894 13.2514 30.4997 12.7617 29.8956 12.7617C29.2916 12.7617 28.8019 13.2514 28.8019 13.8555H30.9894ZM28.8019 13.8555V24.0743H30.9894V13.8555H28.8019ZM28.8764 29.0758C29.0877 28.1865 29.4511 27.3738 29.8401 26.6127C30.1963 25.9153 30.6632 25.1086 30.913 24.4761L28.8783 23.6725C28.6787 24.1781 28.3445 24.7319 27.892 25.6174C27.4722 26.4391 27.0186 27.4318 26.7481 28.5702L28.8764 29.0758ZM33.0432 28.5702C32.7726 27.4318 32.3191 26.4391 31.8992 25.6174C31.4467 24.7319 31.1126 24.1781 30.913 23.6725L28.8783 24.4761C29.1281 25.1086 29.5949 25.9153 29.9512 26.6127C30.3401 27.3738 30.7035 28.1865 30.9149 29.0758L33.0432 28.5702ZM30.3556 29.5326H29.4357V31.7201H30.3556V29.5326ZM26.7481 28.5702C26.5683 29.3275 26.4602 30.3294 27.1737 31.0448C27.5078 31.38 27.9197 31.5373 28.2826 31.6178C28.6442 31.698 29.041 31.7201 29.4357 31.7201V29.5326C29.1027 29.5326 28.8895 29.5118 28.7561 29.4822C28.624 29.4529 28.6559 29.4335 28.7227 29.5005C28.8068 29.5848 28.808 29.6635 28.8039 29.6193C28.7975 29.5509 28.8025 29.3873 28.8764 29.0758L26.7481 28.5702ZM30.9149 29.0758C30.9888 29.3873 30.9938 29.5509 30.9873 29.6193C30.9833 29.6635 30.9844 29.5848 31.0686 29.5005C31.1354 29.4335 31.1673 29.4529 31.0352 29.4822C30.9017 29.5118 30.6885 29.5326 30.3556 29.5326V31.7201C30.7502 31.7201 31.147 31.698 31.5087 31.6178C31.8715 31.5373 32.2835 31.38 32.6176 31.0448C33.331 30.3294 33.223 29.3275 33.0432 28.5702L30.9149 29.0758Z"
                                            fill="#FDD26E"/>
                                    </svg>
                                </div>
                                <p>Educado Księgowość i&nbsp;Kadry to platforma, na której znajdziesz <strong>setki
                                    webinarów
                                    i&nbsp;szkoleń
                                    online</strong> dla profesjonalistów z obszaru KSIĘGOWOŚCI, KADR, HR i&nbsp;PRAWA
                                    PRACY!</p>
                            </Col>
                            <Col lg={3} className="index-banner ">
                                <div className={"icon-wrapper"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="35" viewBox="0 0 36 35"
                                         fill="none">
                                        <path
                                            d="M18.729 32.0847H10.1115C7.85784 32.0847 6.06529 30.988 4.4558 29.4547C1.161 26.3156 6.57059 23.8072 8.63379 22.5787C11.9142 20.6254 15.7927 19.9964 19.4582 20.6919"
                                            stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                        <path
                                            d="M23.104 9.48047C23.104 13.1048 20.1659 16.043 16.5415 16.043C12.9171 16.043 9.979 13.1048 9.979 9.48047C9.979 5.8561 12.9171 2.91797 16.5415 2.91797C20.1659 2.91797 23.104 5.8561 23.104 9.48047Z"
                                            stroke="#FDD26E" strokeWidth="1.5"/>
                                        <path
                                            d="M27.7577 21.2605L28.7842 23.3306C28.9242 23.6186 29.2974 23.8951 29.6124 23.948L31.4731 24.2597C32.6629 24.4596 32.9429 25.33 32.0854 26.1886L30.6389 27.6471C30.3941 27.8942 30.2599 28.3704 30.3357 28.7115L30.7498 30.517C31.0764 31.946 30.3241 32.4988 29.07 31.7519L27.326 30.7111C27.011 30.5228 26.492 30.5228 26.1712 30.7111L24.4272 31.7519C23.179 32.4988 22.4208 31.9402 22.7473 30.517L23.1615 28.7115C23.2373 28.3704 23.1031 27.8942 22.8581 27.6471L21.4116 26.1886C20.5601 25.33 20.8343 24.4596 22.0241 24.2597L23.8847 23.948C24.1938 23.8951 24.5672 23.6186 24.7072 23.3306L25.7337 21.2605C26.2937 20.1371 27.2035 20.1371 27.7577 21.2605Z"
                                            stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <p><strong>Nasi eksperci to uznani specjaliści ds. kadr, płac, księgowości, podatków czy
                                    doradztwa biznesowego</strong> którzy pomogli w osiągnięciu sukcesów tysiącom
                                    klientów!</p>
                            </Col>
                            <Col lg={3} className="index-banner ">
                                <div className={"icon-wrapper"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35"
                                         fill="none">
                                        <path
                                            d="M17.5 2.5H12.5C8.40096 2.5 6.35145 2.5 4.89864 3.51727C4.36114 3.89362 3.89362 4.36114 3.51727 4.89864C2.5 6.35145 2.5 8.40096 2.5 12.5C2.5 16.599 2.5 18.6485 3.51727 20.1014C3.89362 20.6389 4.36114 21.1064 4.89864 21.4827C6.35145 22.5 8.40096 22.5 12.5 22.5H17.5C21.599 22.5 23.6485 22.5 25.1014 21.4827C25.6389 21.1064 26.1064 20.6389 26.4827 20.1014C27.5 18.6485 27.5 16.599 27.5 12.5C27.5 8.40096 27.5 6.35145 26.4827 4.89864C26.1064 4.36114 25.6389 3.89362 25.1014 3.51727C23.6485 2.5 21.599 2.5 17.5 2.5Z"
                                            stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"/>
                                        <path
                                            d="M18.125 27.5L17.7306 26.9764C16.8416 25.7961 16.6211 23.993 17.1835 22.5M11.875 27.5L12.2694 26.9764C13.1584 25.7961 13.3789 23.993 12.8165 22.5"
                                            stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"/>
                                        <path d="M8.75 27.5H21.25" stroke="#FDD26E" strokeWidth="1.5"
                                              strokeLinecap="round"/>
                                        <path
                                            d="M10 17.5C12.2981 14.2557 17.6485 14.0788 20 17.5M17.5 10C17.5 11.3807 16.3807 12.5 15 12.5C13.6193 12.5 12.5 11.3807 12.5 10C12.5 8.61929 13.6193 7.5 15 7.5C16.3807 7.5 17.5 8.61929 17.5 10Z"
                                            stroke="#FDD26E" strokeWidth="1.5" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <p><strong>Szkolenia dostępne są
                                    w wygodnym modelu subskrypcyjnym,</strong> który zapewnia możliwość zdobywania
                                    wiedzy gdzie i&nbsp;kiedy chcesz!</p>
                            </Col>
                        </Row>
                    </div>
                )
            default:
                return (<div></div>
                )
        }
    }


    return (
        <>
            {auth && auth.isAuthenticated ?
                <Container>
                    <div><h1 className={"category-index-headline"}>Witaj {auth.userData.name},<br/>zobacz
                        najnowsze szkolenia dla Ciebie.</h1></div>
                </Container> : getContent(id)}
        </>
    )
}

export default CategoryBanner;