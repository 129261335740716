import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import WebinarForm from "./WebinarForm";
import dateFormat from "dateformat";
import {Helmet} from "react-helmet";
import ExpertContainer from "../../Modules/Experts/Sliders/ExpertSliderContainer";
import AwardsSlider from "../../Modules/AwardsBanner/AwardsSlider";
import ProductContent from "../ProductContent";
import RightSection from "../RightSection";
import {parseTrainingTime, strNormalize} from "../../../common/utils";
import Newsletter from "../Newsletter";
import striptags from "striptags";

class WebinarRegistration extends React.Component {

    render() {
        const {product} = this.props;

        return (
            <>
                <Container fluid className={`webinar-wave-container ${strNormalize(product.categories[0].name)}`}>
                    <Container className={"webinar-wave-container-content"}>
                        <Row>
                            <Col lg={4} md={6} className={'webinar-data'}><h1>{product.name}</h1>
                                <h2>Ekspert:
                                    {product.experts ?
                                        <>{product && product.experts.map((expert, key) => (
                                            <span key={key}>&nbsp;
                                                {expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}
                                            </span>
                                        ))}
                                        </>
                                        : ''
                                    }
                                </h2>
                                <span
                                    className={"webinar-duration white"}>{parseTrainingTime(product.timetable[0].duration)}</span>
                                <div className={"webinar-dates-container"}>
                                    {product && product.timetable.map((date, index) => (
                                        <div key={index}
                                             className={date.expired ? 'webinar-date-item expired' : 'webinar-date-item'}>
                                            <span>{index === 0 ? <>1&nbsp;</> : index + 1} TERMIN</span><p>
                                            <span
                                                className={"time"}>{dateFormat(Date.parse(date.eventStartDate), 'HH:MM')}</span><span
                                            className="date">
                                            &nbsp;| {dateFormat(Date.parse(date.eventStartDate), 'dd-mm-yyyy')}</span></p></div>
                                    ))}

                                </div>
                                {product.categories[0].name === "Oświata" ?
                                    <Button className={"webinar-btn reverse btn btn-primary"}
                                            href={"/oswiata/plan-dla-szkoly"}>Educado dla szkół</Button> : ''}

                                <Button href={"#WebinarForm"} className={"webinar-btn reverse"}>Zapisz się
                                    bezplatnie</Button>

                            </Col>
                            <Col lg={8} md={6} className={"webinar-image"}>
                                <div className={"webinar-image-badge"}>
                                    <div className={"webinar-image-badge-type"}>webinar bezpłatny</div>
                                    <div className={`webinar-image-badge-category badge-${strNormalize(product.categories[0].name)}`}>{product.categories[0].name}</div>
                                </div>
                                {product.imageUrl
                                    ? <img src={product.imageUrl} alt={product.name}/>
                                    : <img src={"/zaslepka.png"} alt={"webinar"}/>}

                                {/*<div className={"webinar-benefit-container"}>*/}
                                {/*    <div className={"webinar-benefit-item item-1"}><img src={"/blue/clock-blue.svg"}*/}
                                {/*                                                        alt={"45-minutowe wystąpienie eksperta"}/><span>45-minutowe<br/>wystąpienie eksperta</span>*/}
                                {/*    </div>*/}
                                {/*    <div className={"webinar-benefit-item item-2"}><img src={"/blue/clock-blue.svg"}*/}
                                {/*                                                        alt={"48-godzinny dostęp do nagrania"}/><span>48-godzinny<br/>dostęp do nagrania</span>*/}
                                {/*    </div>*/}
                                {/*    <div className={"webinar-benefit-item item-3"}><img*/}
                                {/*        src={"/blue/certificate-blue.svg"}*/}
                                {/*        alt={"Imienny certyfikat"}/><span>Imienny certyfikat</span>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container fluid className={"webinar-form-section"}>
                    <Container>
                        <Row>
                            <Col className={"webinar-details-container"} lg={8} md={6}>
                                <Container className={"webinar-details-container-content"}>
                                    <ProductContent children={this.props}/>
                                    <div className={"webinar-content-benefits-items"}>
                                        <div className={"content-benefit-item item-1"}>
                                            <div className={"img-wrapper"}><img src={"/blue/clock-blue.svg"}
                                                                                alt={"45-minutowe wystąpienie eksperta"}/>
                                            </div>
                                            <p>45-minutowe<br/>wystąpienie eksperta</p>
                                        </div>
                                        <div className={"content-benefit-item item-1"}>
                                            <div className={"img-wrapper"}><img src={"/blue/clock-blue.svg"}
                                                                                alt={"48-godzinny dostęp do nagrania"}/>
                                            </div>
                                            <p>48-godzinny<br/>dostęp do nagrania</p>
                                        </div>
                                        {/*<div className={"content-benefit-item item-3"}>*/}
                                        {/*    <div className={"img-wrapper"}><img src={"/blue/certificate-blue.svg"}*/}
                                        {/*                                        alt={"Imienny certyfikat"}/>*/}
                                        {/*    </div>*/}
                                        {/*    <p>Imienny certyfikat</p>*/}
                                        {/*</div>*/}
                                        {/*<div className={"content-benefit-item item-4"}>*/}
                                        {/*    <div className={"img-wrapper"}><img src={"/blue/papers-blue.svg"}*/}
                                        {/*                                        alt={"Prezentacja PDF z webinaru"}/>*/}
                                        {/*    </div>*/}
                                        {/*    <p>Prezentacja PDF<br/>z webinaru</p>*/}
                                        {/*</div>*/}
                                    </div>
                                    <h3 className={"mt-5"}>Szczegóły organizacyjne</h3>
                                    <p>Link do webinarium wysyłamy na podany przy rejestracji adres mailowy Uczestnika/Uczestników, dzień przed terminem wydarzenia oraz w dniu wydarzenia.
                                    </p>
                                    <p>Odtwarzanie nie wymaga instalowania dodatkowych programów. Rekomendujemy korzystanie z przeglądarki Chrome lub Firefox oraz zapewnienie stabilnego łącza internetowego.</p>
                                    <Button href={"#WebinarForm"} className={"mt-5 webinar-btn reverse"}>Zapisz się bezpłatnie</Button>
                                </Container>
                            </Col>
                            <Col className={"webinar-form-container"} lg={4} md={6}>
                                <WebinarForm dates={product.timetable}/>
                                {/*<Newsletter/>*/}
                                <RightSection product={product}/>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                {product.ads && product.ads.map((item, index) => {
                    if (item.zone === "leaderboard") {
                        return (
                            <Container key={index} className={"text-center mb-5"}>
                                <div className={"product-banner banner-front"}>
                                    {item.url ? <a target={'_blank'} href={item.url} rel='noreferrer'>
                                        <img alt={item.name} src={item.imageUrl}/>
                                    </a> : ''}

                                    <Helmet>
                                        <script>{item.js}</script>
                                    </Helmet>
                                </div>
                            </Container>
                        )
                    }
                    return '';
                })}
                <ExpertContainer/>
                <AwardsSlider/>
            </>
        )
    }
}

export default WebinarRegistration;
